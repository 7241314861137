body {
    height: 100vh;
}
.login-page-switcher select {
    width: 150px;
    padding: 7px;
    border-radius: 2px;
}
.login-bg-img {
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 50px 15px 30px;
    position: relative;
    z-index: 1;
}
.login-bg-img::before {
    content: "";
    height: 100%;
    width: 100%;
    background-color: rgba(4, 4, 14, 0.6);
    left: 0;
    top: 0;
    position: absolute;
    z-index: -1;
}
.login-intro {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    max-width: 480px;
    width: 100%;
}
.login-intro h1 {
    color: #fff;
    font-weight: 700;
}
.login-intro p {
    color: #fff;
    margin-bottom: 50px;
}
.login-intro .login-page-switcher {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-bottom: 25px;
}
.login-intro .login-page-switcher .switcher-text1 {
    color: #fff;
    font-size: 18px;
    margin-right: 15px;
    padding-right: 15px;
    border-right: 1px solid #f1f1f1;
    line-height: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}
.login-form {
    margin-bottom: 40px;
}
.login-bg-color {
    background-color: #ffffff;
    min-height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 10px 15px 52px;
}
.login-content {
    max-width: 450px;
    width: 100%;
}
.login-logo {
    display: block;
    margin-bottom: 1px;
    max-width: 180px;
}
.login-form h2 {
    font-weight: 700;
    margin-bottom: 5px;
}
.login-form p {
    margin-bottom: 30px;
    font-size: 17px;
    color: #646464;
}
img {
    max-width: 100%;
    height: auto;
}
.login-intro .login-page-switcher .switcher-text1.active {
    color: #e9b102;
}
.login-page-switcher .switcher-text1:last-child {
    margin-right: 0;
    padding-right: 0;
    border-right: 0;
}
form.user .form-control-user {
    font-size: .8rem;
    border-radius: 10rem;
    padding: 1rem 1rem;
    height: auto;
}
form.user .btn-user {
    font-size: .8rem;
    border-radius: 10rem;
    padding: .75rem 1rem;
}
hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
        border-top-color: currentcolor;
        border-top-style: none;
        border-top-width: 0px;
    border-top: 1px solid rgba(0,0,0,.1);
}