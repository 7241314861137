.page-title {
    padding-top: 10px;
    padding-bottom: 30px;
}
.page-title .breadcrumb {
    background-color: rgba(0,0,0,0);
    padding: 0;
    margin-bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.page-title .breadcrumb li {
    font-size: 13px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
}
.page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: "/";
}
.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/");
}
.page-title .breadcrumb li {
    font-size: 13px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
}
.page-title .row h3 {
    font-size: 24px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.form-select {
    display: block;
    width: 100%;
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    vertical-align: middle;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.checkbox input[type="checkbox"] {
    opacity: 0;
}
.form-check-input[type="checkbox"] {
    border-radius: .25em;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    -webkit-transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    border: 1px solid #efefef;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox input[type="checkbox"]:checked + label::before {
    font-family: themify;
    content: "\e64c";
    text-align: center;
    line-height: 1.2;
}
.form-check {
    display: block;
    min-height: 2rem;
    padding-left: 2em;
    margin-bottom: .125rem;
}
.btn-pill {
    border-radius: 60px;
    padding: 12px 10px;
}
.card .card-footer {
    background-color: #fff;
    border-top: 1px solid #ecf3fa;
    padding: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}





.custom-radio-ml {
    margin-left: 3px;
}
.m-t-15 {
    margin-top: 15px !important;
}
.radio input[type="radio"] {
    opacity: 0;
}
.form-check-input[type="radio"] {
    border-radius: 50%;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.radio label {
    display: inline-block;
    position: relative;
    padding-left: 5px;
    cursor: pointer;
}
.radio-primary input[type="radio"] + label::before {
    border-color: var(--theme-deafult);
}
.radio label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    margin-left: -20px;
    border: 1px solid #efefef;
        border-top-color: rgb(239, 239, 239);
        border-right-color: rgb(239, 239, 239);
        border-bottom-color: rgb(239, 239, 239);
        border-left-color: rgb(239, 239, 239);
    border-radius: 50%;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out;
    transition: border 0.15s ease-in-out;
}
.radio-primary input[type="radio"]:checked + label::before {
    border-color: #7467fc;
}
.radio-primary input[type="radio"]:checked + label::after {
    background-color: #7467fa;
}
.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}
.radio-secondary input[type="radio"] + label::before {
    border-color: #f73164;
}
.radio-secondary input[type="radio"]:checked + label::after {
    background-color: #f73164;
}
.radio-success input[type="radio"] + label::before {
    border-color: #51bb25;
}
.radio-success input[type="radio"] + label::after {
    background-color: #51bb25;
}
.radio-info input[type="radio"] + label::before {
    border-color: #a927f9;
}
.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f8d62b;
}
.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f8d62b;
}
.radio-danger input[type="radio"] + label::before {
    border-color: #dc3545;
}
.radio-danger input[type="radio"] + label::after {
    background-color: #dc3545;
}
.custom-radio-ml {
    margin-left: 3px;
}
.m-checkbox-inline .radio {
    display: inline-block;
}
.form-check-inline {
    display: inline-block;
    margin-right: 1rem;
}
.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 9px;
    height: 9px;
    left: 5px;
    top: 5px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #898989;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #f4f4f4;
    cursor: not-allowed;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
    border-color: #7366fe;
    color: #7568fe;
}
.checkbox-secondary label::before {
    border-color: #f73164;
}
.checkbox-success label::before {
    border-color: #51bb25;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
    border-color: #51bb25;
    color: #51bb25;
}
.m-checkbox-inline label {
    margin-right: 20px;
}