#wrapper {
    display: flex;
}

.sidebar {
    width: 14rem !important;
}
.sidebar {
    width: 6.5rem;
    min-height: 100vh;
}

.accordion {
    overflow-anchor: none;
}
.sidebar-dark .sidebar-brand {
    color: #bc972c;
}
.sidebar-dark .nav-item span {
    display: block;
    width: calc(100% - 56px);
    float: right;
    align-self: center;
}
.sidebar .sidebar-brand {
    height: 4.375rem;
    text-decoration: none;
    font-size: 1rem;
    font-weight: 700;
    padding: 1.5rem 1rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .05rem;
    z-index: 1;
}
.sidebar .sidebar-brand .sidebar-brand-text {
    display: inline;
    font-size: 26px;
    margin-top: -8px;
}
.sidebar .nav-item {
    position: relative;
}
.sidebar-dark .nav-item.active .nav-link {
   color: #333;
}
.sidebar-dark .nav-item.active .nav-link i {
    background-color: #242424;
    border-color: #242424;
    color: #fff;
}
.sidebar .nav-item:hover .nav-link i {
    background-color: #242424;
    border-color: #242424;
    color: #fff;
}
.sidebar .nav-item.active .nav-link {
    font-weight: 600;
}
.topbar-button {
    background-color: #87f1f97d;
    height: 45px !important;
    width: 45px;
    border-radius: 13px;
}
.language_select select {
    margin-left: 10px;
    display: inline-block;
    padding: 9px 5px;
    text-transform: capitalize;
    font-size: 14px;
    border-radius: 5px;
    border: 1px solid rgba(0, 0, 0, 0.17);
    height: 45px;
}
.sidebar-dark .nav-item .nav-link {
    color: #444;
}
.sidebar .nav-item .nav-link {
    display: flex;
    width: 100%;
    text-align: left;
    padding: 0.8rem 1rem;
    width: 14rem;
}
.sidebar .nav-item .nav-link i {
    width: 45px;
    height: 45px;
    border-radius: 2px 12px;
    /* background-color: #fbfbfb; */
    line-height: 2.5;
    display: inline-block;
    color: #3eced5;
    border: 1px solid #3eced5;
    margin-right: 10px;
}
.sidebar .nav-item .nav-link, .topbar .nav-item .nav-link {
    position: relative;
}
.sidebar .nav-item:hover .nav-link {
    color: #242424;
}
.sidebar .nav-item .collapse, .sidebar .nav-item .collapsing {
    margin: 0 1rem;
}
.sidebar .nav-item .collapse {
    position: relative;
    left: 0;
    z-index: 1;
    top: 0;
    -webkit-animation: none;
    animation: none;
}
.sidebar .nav-item .collapse .collapse-inner, .sidebar .nav-item .collapsing .collapse-inner {
    padding: .5rem 0;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
    min-width: 10rem;
    font-size: .85rem;
    margin: 0 0 1rem 0;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-header, .sidebar .nav-item .collapsing .collapse-inner .collapse-header {
    margin: 0;
    white-space: nowrap;
    padding: .5rem 1.5rem;
    text-transform: uppercase;
    font-weight: 800;
    font-size: .65rem;
    color: #b7b9cc;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item, .sidebar .nav-item .collapsing .collapse-inner .collapse-item {
    padding: .5rem 1rem;
    margin: 0 .5rem;
    display: block;
    color: #3a3b45;
    text-decoration: none;
    border-radius: .35rem;
    white-space: nowrap;
}
.btn-light{
    background-color: rgb(0 123 255 / 25%);
}
.user__profile img {
    display: block;
}
#wrapper #content-wrapper {
    background-color: #f0f4f9;
    width: 100%;
    overflow-x: hidden;
}
#wrapper #content-wrapper #content {
    flex: 1 0 auto;
}
.topbar {
    height: 4.45rem;
}
.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start;
}
.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    padding: .5rem 1rem;
    border-bottom: 1px solid #ddd;
}
.topbar #sidebarToggleTop {
    height: 2.5rem;
    width: 2.5rem;
}
/* .topbar .navbar-search {
    width: 25rem;
} */
.form-inline .custom-select, .form-inline .input-group {
    width: auto;
}
.topbar .navbar-search input {
   font-size: .85rem;
    height: auto;
    padding: 0.9rem;
    border-radius: 15px !important;
}
.topbar .dropdown {
    position: relative;
}
.ml-55 {
    margin-left: -55px;
}
.sidebar .nav-item .nav-link .badge-counter, .topbar .nav-item .nav-link .badge-counter {
    position: absolute;
    transform: scale(.7);
    transform-origin: top right;
    right: .25rem;
    margin-top: -.25rem;
}
.badge-danger {
    color: #fff;
    background-color: #e74a3b;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link {
    color: #242424;
}
.title-top {
    font-weight: 600;
    color: #242424;
    text-transform: capitalize;
}
.topbar .nav-item .nav-link {
    height: 45px;
    display: flex;
    align-items: center;
    padding: 0 .75rem;
}
.sidebar .nav-item .nav-link .img-profile, .topbar .nav-item .nav-link .img-profile {
    height: 45px;
    width: 45px;
    background-color: #fff;
    padding: 4px;
    border: 1px solid #eee;
    border-radius: 13px;
    object-fit: cover;
}
.topbar .dropdown .dropdown-menu {
    width: auto;
    right: 0;
}
.dropdown-item {
    display: block;
    width: 100%;
    padding: .25rem 1.5rem;
    clear: both;
    font-weight: 400;
    color: #3a3b45;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
}
.text-gray-400 {
    color: #d1d3e2 !important;
}
.shadow {
    box-shadow: 0 .15rem 1.75rem 0 rgba(58,59,69,.15) !important;
}
.dropdown .dropdown-menu {
    font-size: .85rem;
    border: 0px;
}
.dropdown-divider {
    height: 0;
    margin: .5rem 0;
    overflow: hidden;
    border-top: 1px solid #eaecf4;
}
.navbar-nav .nav-link:hover a {
    color: #0677aa;
    text-decoration: none;
}

.nav-link a:hover i {
    color: #ba972d;
    background-color: #242424;
}

.animated--grow-in, .sidebar .nav-item .collapse {
    -webkit-animation-name: growIn;
    animation-name: growIn;
    -webkit-animation-duration: .2s;
    animation-duration: .2s;
    -webkit-animation-timing-function: transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1);
    animation-timing-function: transform cubic-bezier(.18,1.25,.4,1),opacity cubic-bezier(0,1,.4,1);
}
.topbar .dropdown-list .dropdown-header {
    background-color: #4e73df;
    border: 1px solid #4e73df;
    padding-top: .75rem;
    padding-bottom: .75rem;
    color: #fff !important;
}
.dropdown .dropdown-menu .dropdown-header {
    font-weight: 800;
    font-size: .65rem;
    color: #b7b9cc;
}
.topbar .dropdown-list .dropdown-item {
    white-space: normal;
    padding-top: .5rem;
    padding-bottom: .5rem;
    border-left: 1px solid #e3e6f0;
    border-right: 1px solid #e3e6f0;
    border-bottom: 1px solid #e3e6f0;
    line-height: 1.3rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image {
    position: relative;
    height: 2.5rem;
    width: 2.5rem;
}
.topbar .dropdown-list {
    width: 20rem !important;
}
.topbar .dropdown-list {
    padding: 0;
    border: none;
    overflow: hidden;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image img {
    height: 2.5rem;
    width: 2.5rem;
}
.topbar.navbar-light .navbar-nav .nav-item .nav-link:hover {
    color: #39cdd4;
}
.dropdown.no-arrow .dropdown-toggle::after {
    display: none;
}
.topbar .topbar-divider {
    width: 0;
    border-right: 1px solid #e3e6f0;
    height: calc(4.375rem - 2rem);
    margin: auto 1rem;
}
.topbar .dropdown-list .dropdown-item .dropdown-list-image .status-indicator {
    background-color: #eaecf4;
    height: .75rem;
    width: .75rem;
    border-radius: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
    border: .125rem solid #fff;
}
.icon-circle {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.topbar .dropdown-list .dropdown-item .text-truncate {
    max-width: 13.375rem;
}
.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.sidebar .nav-item .collapse .collapse-inner .collapse-item:hover, .sidebar .nav-item .collapsing .collapse-inner .collapse-item:hover {
    background-color: #eaecf4;
}
.radius-0 {
    border-radius: 0px !important;
}
.card{
    border-radius:2px 20px;
}
.radius-15 {
    border-radius: 15px;
}
.list-review-user h5 {
    color: #333;
}
.ratting-user {
    color: #fabe0b;
    font-size: 14px;
}
.ratting-user .grey {
    color: #948f84;
    font-size: 14px;
}
.filter-input {
    height: calc(3.10rem + 2px) !important;
    border-radius: 16px;
    max-width: 200px;
}
.btn-filter i {
    background-color: #fff;
    height: calc(3.07rem + 2px) !important;
    border-radius: 16px;
    line-height: 3;
    color: #444;
    width: 3.10rem;
}
.courses-item {
    padding: 8px;
    background-color: #fff;
    border-radius: 16px;
    transition: all 0.3s ease;
}
.courses-item .img-part img {
    width: 100%;
    transition: all 0.3s ease;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    height: 140px;
    object-fit: cover;
    object-position: top center;
}
.courses-item .content-part {
    position: relative;
    padding: 35px 14px 18px;
}
.font-weight-bold{
font-weight: 600 !important;
}
.courses-item .content-part a.categories {
    background: #ff5421;
    color: #ffffff;
}
.courses-item .content-part a.categories {
    display: inline-block;
    padding: 0 15px;
    height: 36px;
    line-height: 36px;
    color: #ffffff;
    font-weight: 500;
    background: #029ecef0;
    border-radius: 3px;
    transition: all 0.3s ease;
    position: absolute;
    top: -20px;
}
.courses-item .content-part .title a {
    color: #111111;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2; /* number of lines to show */
   -webkit-box-orient: vertical;
}
.courses-item .content-part .title {
    line-height: normal;
    height: 48px;
}
.default_div {
    max-width: 250px;
}
.default_div img {
    opacity: 0.6;
}
.default_div p {
    font-size: 18px;
    color: #8b8b8b;
    text-transform: uppercase;
}
.courses-item .content-part .bottom-part {
    overflow: hidden;
}
.courses-item .content-part .bottom-part .info-meta {
    float: left;
}
.courses-item .content-part .bottom-part .btn-part {
    text-align: right;
    color: #484848;
    font-weight: 500;
    font-size: 14px;
}
.courses-item .content-part .bottom-part .info-meta li:last-child {
    margin: 0;
}
.courses-item .content-part .bottom-part .info-meta li.ratings i {
    color: #fcb903;
}
.courses-item .content-part .bottom-part .info-meta li {
    display: inline;
    margin-right: 15px;
    transition: all 0.3s ease;
}
ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}
.table-img img {
    box-shadow: 0 0 0 2px #fff,1px 1px 5px rgba(0,0,0,.32);
    object-fit: cover;
}
table .course-name h5 {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    color: #222;
    font-size: 16px;
}
.table thead th {
    text-transform: uppercase;
    color: #242424;
    font-size: 14px;
}
.table td{
    color: #444;
    padding: .65rem;
}
.Instrutor-name {
    color: #3dced5;
}
.page-title {
    padding-top: 10px;
    padding-bottom: 30px;
}
.page-title .breadcrumb {
    background-color: rgba(0,0,0,0);
    padding: 0;
    margin-bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.page-title .breadcrumb li {
    font-size: 14px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
}
.page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: "/";
}
.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/");
}
.page-title .breadcrumb li {
    font-size: 13px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
}
.page-title .row h3 {
    font-size: 24px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.form-select {
    display: block;
    width: 100%;
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    vertical-align: middle;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.form-disable {
    background-color: rgb(224, 217, 217);
    display: block;
    width: 100%;
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    vertical-align: middle;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.checkbox input[type="checkbox"] {
    opacity: 0;
}
.form-check-input[type="checkbox"] {
    border-radius: .25em;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    -webkit-transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}
.checkbox label::before {
    content: "";
    display: inline-block;
    position: absolute;
    width: 19px;
    height: 19px;
    left: 0;
    border: 1px solid #efefef;
    border-radius: 3px;
    background-color: #fff;
    -webkit-transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
}
.checkbox input[type="checkbox"]:checked + label::before {
    font-family: themify;
    content: "\e64c";
    text-align: center;
    line-height: 1.2;
}
.form-check {
    display: block;
    min-height: 2rem;
    padding-left: 2em;
    margin-bottom: .125rem;
}
.btn-pill {
    border-radius: 60px;
    padding: 12px 10px;
}
.card .card-footer {
    background-color: #fff;
    border-top: 1px solid #ecf3fa;
    padding: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}
.form-control {
    min-height: 47px;
}
.form-select {
    min-height: 47px;
}
.radio input[type="radio"] {
    opacity: 0;
}
.form-check-input[type="radio"] {
    border-radius: 50%;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}

.radio-primary input[type="radio"] + label::before {
    border-color: var(--theme-deafult);
}
.radio label::before {
    margin-top: 3px;
    border: 2px solid #444 !important;
}
.radio-primary input[type="radio"]:checked + label::before {
    border-color: #7467fc;
}
.radio-primary input[type="radio"]:checked + label::after {
    background-color: #7467fa;
}
.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}
.radio-secondary input[type="radio"] + label::before {
    border-color: #f73164;
}
.radio-secondary input[type="radio"]:checked + label::after {
    background-color: #f73164;
}
.radio-success input[type="radio"] + label::before {
    border-color: #51bb25;
}
.radio-success input[type="radio"] + label::after {
    background-color: #51bb25;
}
.radio-info input[type="radio"] + label::before {
    border-color: #a927f9;
}
.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f8d62b;
}
.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f8d62b;
}
.radio-danger input[type="radio"] + label::before {
    border-color: #dc3545;
}
.radio-danger input[type="radio"] + label::after {
    background-color: #dc3545;
}
.m-checkbox-inline .radio {
    display: inline-block;
}
.form-check-inline{
    margin-right: 0.5rem !important;
}
.form-check-inline {
    display: inline-block;
    margin-right: 1rem;
}
.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 9px;
    height: 9px;
    left: 5px;
    top: 5px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #898989;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #f4f4f4;
    cursor: not-allowed;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
    border-color: #7366fe;
    color: #7568fe;
}
.checkbox-secondary label::before {
    border-color: #f73164;
}
.checkbox-success label::before {
    border-color: #51bb25;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
    border-color: #51bb25;
    color: #51bb25;
}
.m-checkbox-inline label {
    margin-right: 20px;
}

.page-title {
    padding-top: 10px;
    padding-bottom: 30px;
}
.page-title .breadcrumb {
    background-color: rgba(0,0,0,0);
    padding: 0;
    margin-bottom: 0;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}
.page-title .breadcrumb li {
    font-size: 13px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
}
.page-title .breadcrumb .breadcrumb-item + .breadcrumb-item::before {
    content: "/";
}
.breadcrumb-item + .breadcrumb-item::before {
    float: left;
    padding-right: .5rem;
    color: #6c757d;
    content: var(--bs-breadcrumb-divider, "/");
}
.page-title .breadcrumb li {
    font-size: 13px;
    letter-spacing: 1px;
    font-family: 'Poppins', sans-serif;
}
.page-title .row h3 {
    font-size: 24px;
    margin-bottom: 0;
    text-transform: capitalize;
}
.form-select {
    display: block;
    width: 100%;
    padding: .375rem 1.75rem .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    vertical-align: middle;
    background-color: #fff;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right .75rem center;
    background-size: 16px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu), .input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.checkbox input[type="checkbox"] {
    opacity: 0;
}
.form-check-input[type="checkbox"] {
    border-radius: .25em;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.form-check-input {
    width: 1em;
    height: 1em;
    margin-top: .25em;
    vertical-align: top;
    background-color: #fff;
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    border: 1px solid rgba(0,0,0,0.25);
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    -webkit-transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out;
    transition: background-color 0.15s ease-in-out,background-position 0.15s ease-in-out,border-color 0.15s ease-in-out,box-shadow 0.15s ease-in-out,-webkit-box-shadow 0.15s ease-in-out;
}
.checkbox label::before {
    border: 2px solid #1851aa !important;
    width: 24px !important;
    height: 24px !important;
}
.accordion-top-set .form-check-label {
    margin-top: -6px;
    text-transform: capitalize;
    color: #343a40;
}
.form-check {
    display: block;
    min-height: 2rem;
    padding-left: 2em;
    margin-bottom: .125rem;
}
.btn-pill {
    border-radius: 60px;
    padding: 12px 10px;
}
.card .card-footer {
    background-color: #fff;
    border-top: 1px solid #ecf3fa;
    padding: 20px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.m-t-15 {
    margin-top: 15px !important;
}
.radio input[type="radio"] {
    opacity: 0;
}
.form-check-input[type="radio"] {
    border-radius: 50%;
}
.form-check .form-check-input {
    float: left;
    margin-left: -1.5em;
}
.radio-primary input[type="radio"] + label::before {
    border-color: var(--theme-deafult);
}
.radio label::after{
    margin-top: 3px;
}
.radio-primary input[type="radio"]:checked + label::before {
    border-color: #39ccd4 !important;
}
.radio-primary input[type="radio"]:checked + label::after {
    background-color: #39ccd4 !important;
}
.radio input[type="radio"]:checked + label::after {
    -webkit-transform: scale(1, 1);
    transform: scale(1, 1);
}
.radio-secondary input[type="radio"] + label::before {
    border-color: #f73164;
}
.radio-secondary input[type="radio"]:checked + label::after {
    background-color: #f73164;
}
.radio-success input[type="radio"] + label::before {
    border-color: #51bb25;
}
.radio-success input[type="radio"] + label::after {
    background-color: #51bb25;
}
.radio-info input[type="radio"] + label::before {
    border-color: #a927f9;
}
.radio-warning input[type="radio"]:checked + label::before {
    border-color: #f8d62b;
}
.radio-warning input[type="radio"]:checked + label::after {
    background-color: #f8d62b;
}
.radio-danger input[type="radio"] + label::before {
    border-color: #dc3545;
}
.radio-danger input[type="radio"] + label::after {
    background-color: #dc3545;
}

.m-checkbox-inline .radio {
    display: inline-block;
}
.form-check-inline {
    display: inline-block;
    margin-right: 1rem;
}
.radio label::after {
    display: inline-block;
    position: absolute;
    content: " ";
    width: 9px;
    height: 9px;
    left: 5px;
    top: 5px;
    margin-left: -20px;
    border-radius: 50%;
    background-color: #898989;
    -webkit-transform: scale(0, 0);
    transform: scale(0, 0);
    -webkit-transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33), -webkit-transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33);
}
.checkbox input[type="checkbox"]:disabled + label::before {
    background-color: #f4f4f4;
    cursor: not-allowed;
}
.checkbox-primary input[type="checkbox"]:checked + label::before {
    border-color: #7366fe;
    color: #7568fe;
}
.checkbox-secondary label::before {
    border-color: #f73164;
}
.checkbox-success label::before {
    border-color: #51bb25;
}
.checkbox-success input[type="checkbox"]:checked + label::before {
    border-color: #51bb25;
    color: #51bb25;
}
.m-checkbox-inline label {
    margin-right: 20px;
}
.table td, .table th{
    border-top: 0px;
    border-bottom: 1px solid #ddd;
}
button.btn.btn-primary.submit_answer {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    font-weight: 600;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
}
.box {
    min-width: 300px;
    /* margin: 15px auto; */
    background: #fff1f1;
    padding: 12px;
    text-align: left;
    font-weight: 500;
    color: #222;
    position: relative;
  }
  .sb3:before {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    border-left: 10px solid #fff1f1;
    border-right: 10px solid transparent;
    border-top: 10px solid #fff1f1;
    border-bottom: 10px solid transparent;
    left: 19px;
    bottom: -19px;
  }
  .card-header{
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
  }
  .btn {
    text-transform: capitalize;
}
@media screen and (min-width:1170px) {
    .topbar .navbar-search {
        width: 20rem;
    }
    #userDropdown {
        padding-right: 0;
    }
    #userDropdown .mr-2.d-none.d-lg-inline.text-dark.font-weight-bold {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 150px;
    }
}
  @media screen and (max-width:767px) {
    .m-p-0 {
        padding: 0;
    }
    button.btn.btn-primary.submit_answer{
        position: relative;
        margin-top: 1rem;
        border-radius: 4px;
        width: 100%;
        float: left;
    }
    .nav-link{padding: 0.5rem 0.5rem;}
    .nav.nav-tabs{margin-left: 0px !important;}
    #wrapper #content-wrapper{    min-height: 80vh;}
    .interactivity_card{padding: 15px 0px 10px;}
    .language_select select{
        height: auto !important;
    }
    .m-overflow-h{
        overflow: hidden;
    }
    .topbar .navbar-search {
        width: auto;
    }
    .page-title .breadcrumb{
        justify-content: flex-start !important;
    }
    #responsive-navbar-nav {
        position: absolute;
        top: 3.5rem;
        background-color: #fff;
        width: 100%;
        left: 0;
        padding: 15px;
        border-top: 1px solid #ddd;
    }
    .nav-link a i {
        width: 45px;
        height: 45px;
        border-radius: 12px;
        background-color: #fbfbfb;
        line-height: 2.5;
        display: inline-block;
        color: #838383;
        border: 1px solid #eee;
        margin-right: 10px;
    }
    .nav-link a {
        color: #222;
        font-weight: 600;
    }
    .filter-input{
        max-width: 100%;
    }
    .responsiveTable tbody tr {  
        border-bottom: 1px solid #888787 !important;
    }
    .responsiveTable tbody tr {
        border-top-width: 0px !important;
        border-left-width: 0px !important;
        border-right-width: 0px !important;
    }
    
  }
  .loader_block {
    position: fixed;
    display: block;
    left: 0%;
    top: 0%;
    width: 100%;
    height: 100%!important;
    z-index: 9999;
    background-color: rgba(255, 255, 255, 0.7);
}

.loader_block span {
    left: 50%;
    top: 50%;
    position: relative;
}

.hide-display {
    display: none;
}

div[disabled]
{
  pointer-events:none;
  opacity: 0.5;
  cursor:not-allowed;
}
.give_rate .rate-select {
    color: #FFC107;
 }
 .give_rate {
    color: #555555;
 }
 .ul-list ul{
    list-style: unset;
    margin: unset;
    padding: 10px !important;
 }