.nav.nav-tabs {
  margin-left: 2rem;
}
.nav-tabs .nav-link {
  color: #222;
}

.pagination > .active > a{
  background-color: #47ccde ;
  border-color: #47ccde ;
  color: #fff;
}
.pagination > li > a{
  border: 1px solid #adb5bd;
  padding: 8px 15px;
  outline: none;
  cursor: pointer;
  margin: 3px;
  text-transform: uppercase;
  border-radius: 5px;
}
.pagination > .active > a, .pagination > .active > span, .pagination > .active > a:hover, .pagination > .active > span:hover, .pagination > .active > a:focus, .pagination > .active > span:focus{
  background-color: #147cde ;
  border-color: #147cde;
  outline: none ;
  
}
.pagination > li > a, .pagination > li > span{
  color: #47ccde
}
.pagination > li:first-child > a, .pagination > li:first-child > span, .pagination > li:last-child > a, .pagination > li:last-child > span{
  border-radius: 2px
}
.badge_count_event {
  display: inline-block;
  float: right;
  width: 25px;
  height: 25px;
  background-color: #189dd9;
  text-align: center;
  font-size: 12px;
  border-radius: 50%;
  color: #fff;
  padding-top: 3px;
}